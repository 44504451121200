import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import './Content.css';
//import ResponseModal from './ResponseModal.js';
import { CircularProgress } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const pocDetails = {
    "Aids for daily living": "01",
    "Ambulance services": "02",
    "Audio (hearing) services": "03",
    "Hospital service": "05",
    "Medical supplies": "06",
    "Nursing services": "08",
    "Oxygen therapy": "09",
    "Prosthetics and orthotics": "11",
    "Related health services": "12",
    "Special equipment": "13",
    "Vision (eye) care": "14"
};

function Row(props) {
    const { row } = props;
    let pocDescription;
    const [open, setOpen] = React.useState(false);
    for (let [key, value] of Object.entries(row)) { 
        if (typeof value === "string" && value.trim() === "") { 
            row[key] = "N/A"; 
        }
    }

    for (var key in pocDetails) {
        if (pocDetails[key] === row.poc) {
            pocDescription = key
            break;
        }
    }
    let newKeyValue = { lang_code: props.row.lang_code, tax_code: props.row.tax_code, policy_ref: props.row.policy_ref, travel_qualifier: props.row.travel_qualifier, comments: props.row.comments, frequency: props.row.frequency };
    row.details = [newKeyValue];

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.benefit_code}
                </TableCell>
                <TableCell >{row.province}</TableCell>
                <TableCell >{pocDescription}</TableCell>
                <TableCell >{row.limit}</TableCell>
                <TableCell >{row.negotiated_fee}</TableCell>
                <TableCell >{row.description}</TableCell>
                <TableCell >{row.week}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ marginBottom: 5 }}>
                            <Typography gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="details">
                                <TableHead>
                                    <TableRow>
                                        
                                        <TableCell>Language Code</TableCell>
                                        <TableCell>Tax Code</TableCell>
                                        <TableCell>Policy Ref</TableCell>
                                        <TableCell>Travel Qualifier</TableCell>
                                        <TableCell>Comments</TableCell>
                                        <TableCell>Frequency</TableCell>
              
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.details.map((lowerRow) => (
                                        <TableRow key={replaceEmptyValue(lowerRow.lang_code)}>
                                            <TableCell component="th" scope="row">
                                                {replaceEmptyValue(lowerRow.lang_code)}
                                            </TableCell>
                                            <TableCell>{replaceEmptyValue(lowerRow.tax_code) }</TableCell>
                                            <TableCell align="center">{replaceEmptyValue(lowerRow.policy_ref) }</TableCell>
                                            <TableCell align="center">{replaceEmptyValue(lowerRow.travel_qualifier) }</TableCell>
                                            <TableCell align="center">{replaceEmptyValue(lowerRow.comments) }</TableCell>
                                            <TableCell align="center">{replaceEmptyValue(lowerRow.frequency) }</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function replaceEmptyValue(value) {
    if (value == "" || value == null) {
        return "N/A"
    }
    return value
}

export const Content = props => {

    const benefits = useSelector((state) => state.benefits);
    const contentClass = props.isOpen ? "content open" : "content";
    const table = document.getElementById('table_content')
    const { row } = props;
    const [open, setOpen] = useState(false);

    // use the following if you need popups
    // const [showModal, setShowModal] = useState(false);
    //const handleOpenModal = () => {
    //    setShowModal(true);
    //};

    //const handleCloseModal = () => {
    //    setShowModal(false);
    //};


    if (benefits) {
        return (
            <div id="table_content" className={contentClass}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><div className="bold">Benefit Code</div></TableCell>
                                <TableCell><div className="bold">Province</div></TableCell>
                                <TableCell><div className="bold">Program of choice</div></TableCell>
                                <TableCell><div className="bold">Limit</div></TableCell>
                                <TableCell><div className="bold">Negotiated Fee</div></TableCell>
                                <TableCell><div className="bold">Description</div></TableCell>
                                <TableCell><div className="bold">Effective date</div></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>             
                            {props.otherProps !== null ? !props.otherProps.formValidation ? <div></div> :
                                props.otherProps.formValidation && props.queryData === benefits ?
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <CircularProgress style={{ marginTop: 30, marginRight: 50 }} /> 
                                        </TableCell>
                                    </TableRow>
                                    :
                            props.otherProps.formValidation && props.otherProps.instance && benefits.length === 0 ?
                                        <label style={{ color: 'black' }}>No data to show</label> :
 
                                        benefits.map((row) => (
                                            <Row key={row.benefit_code} row={row} />
                                            
                            )) : <div></div>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>

                </div>
            </div>
        );
    }
    else {
        return (
            <div className={contentClass}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><div className="bold">Benefit Code</div></TableCell>
                                <TableCell><div className="bold">Province</div></TableCell>
                                <TableCell><div className="bold">Program of choice</div></TableCell>
                                <TableCell><div className="bold">Rate</div></TableCell>
                                <TableCell><div className="bold">Description</div></TableCell>
                                <TableCell><div className="bold">Effective date</div></TableCell>
                            </TableRow>
                        </TableHead>
                        
                    </Table>
                </TableContainer>

                {/*use the following code for popups*/}
                {/*<ResponseModal*/}
                {/*    displayModal={true}*/}
                {/*    closeModal={handleCloseModal}*/}
                {/*/>*/}
            </div>
        )

    }


}