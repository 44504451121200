import { GET } from '../constants/constants.js';
import * as api from '../api/index.js';

export const getBenefits = (poc, province, benefitCode, fromDate, toDate, keywords) => async (dispatch) => {
    try {
        //console.log("Poc: " + poc + "\nProvince: " + province + "\nBenefitCode: " + benefitCode + "\nFromDate: " + fromDate + "\nToDate: " + toDate);
        const { data } = await api.getBenefits(poc, province, benefitCode, fromDate, toDate, keywords);
        dispatch({ type: GET, payload: data });
    } catch (error) {
        console.log("Name: " + error.name + "\nMessage: " + error.message + "\nStack: " + error.stack);
    }
};
