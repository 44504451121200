import { useState, useEffect, useContext } from 'react';
import './Sform.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getBenefits } from '../actions/benefits.js';
import { lastDayOfDecade } from 'date-fns/esm/fp';


export const Sform = props => {

    const [poc, setPOC] = useState("");
    const [province, setProvince] = useState("AB");
    const [benefitCode, setBenefitCode] = useState("");
    const benefits = useSelector((state) => state.benefits);
    const [fromDate, setFromDate] = useState("");
    const [fromDateFull, setFromDateFull] = useState(getFirstDayOfCurrentMonth());
    const [toDateFull, setToDateFull] = useState(getLastDayOfCurrentMonth());
    const [toDate, setToDate] = useState("");
    const [keywords, setKeywords] = useState("");
    const dispatch = useDispatch();


    let handleSubmit = (e) => {

        e.preventDefault();
        props.onSubmit(benefits)


        // form validations
        var isValidFormFill = true
        var benefitCodeError = document.getElementById("benefit_code_error")
        var fromDate_error = document.getElementById("fromDate_error")
        var toDate_error = document.getElementById("toDate_error")
        //if (benefitCode.trim() === "") {
        //    benefitCodeError.style.display = 'block'
        //    isValidFormFill = false
        //}

        if (fromDate === null) {
            fromDate_error.style.display = 'block'
            isValidFormFill = false
        }

        if (toDate === null) {
            toDate_error.style.display = 'block'
            isValidFormFill = false
        }

        if (!isValidFormFill) {
            props.otherProps({ instance: true, formValidation: isValidFormFill })
            return
        }
   
        //    let url = `http://localhost:51626/benefitsgrid/app/search?poc=${poc}&province=${province}&benefit_code=${benefitCode}&from=${fromDate}&to=${toDate}`
        props.otherProps({ instance: true, formValidation: isValidFormFill })

        dispatch(getBenefits(poc, province, benefitCode, fromDate, toDate, keywords))


    };

    function handleBenefitCode(e) {
        //setBenefitCode(e.target.value)
        //var benefitCodeError = document.getElementById("benefit_code_error")
        //benefitCodeError.style.display = 'none'
        if (benefitCode != "") {
            setBenefitCode("");
        }
        if (e.target.value != "All") {
            setBenefitCode(e.target.value)
        }
    }

    function handlePOC(e) {

        let pocCode = "";
        if (poc != "") {
            setPOC("");
        }


        if (e.target.value != "All") {
            pocCode = e.target.value.substring(0, 2);
            setPOC(pocCode)
        }
        else {
            setPOC(pocCode)
        }


    }

    function handleProvince(e) {
        if (province != "") {
            setProvince("");
        }


        if (e.target.value != "All") {
            setProvince(e.target.value)
        }
    }

    function processFromDateFormat(e) {
        if (e == null) {
            setFromDate(null)
            return
        }
        //console.log(e)
        //console.log(e.$M)

        let month = (e.$M + 1)
        let day = e.$D
        if (month < 10) {
            month = '0' + String(month)
        }
        if (day < 10) {
            day = '0' + String(day)
        }
        let date = String(e.$y) + '-' + month + '-' + day

        //console.log(date)
        setFromDate(date)
        setFromDateFull(e)
        var fromDate_error = document.getElementById("fromDate_error")
        fromDate_error.style.display = 'none'

    }

    function processToDateFormat(e) {
        if (e == null) {
            setToDate(null)
            return
        }
        //console.log(e)
        //console.log(e.$M)

        let month = (e.$M + 1)
        let day = e.$D
        if (month < 10) {
            month = '0' + String(month)
        }
        if (day < 10) {
            day = '0' + String(day)
        }
        let date = String(e.$y) + '-' + month + '-' + day

        setToDate(date)
        setToDateFull(e)
        var toDate_error = document.getElementById("toDate_error")
        toDate_error.style.display = 'none'
    }

    function handleKeywords(e) {
        setKeywords(e.target.value);

    }

    function getFirstDayOfCurrentMonth() {
        const today = new Date(); 
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const firstDayFormatted = `${year}-${month.toString().padStart(2, '0')}-01`;

        return firstDayFormatted.toString()

    }

    function getLastDayOfCurrentMonth() {
        const today = new Date(); 
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const lastDayOfMonth = new Date(year, month, 0);
        const lastDayFormatted = `${year}-${month.toString().padStart(2, '0')}-${lastDayOfMonth.getDate().toString().padStart(2, '0')}`;


        return lastDayFormatted.toString()

    }

    return (
        <div>
            <div className='head1'> Find Benefit</div>
            <div>
                <Box component="form" className='separator'>
                    <div className='separator'>
                        <FormControl fullWidth>
                            <InputLabel>Program of choice</InputLabel>
                            <Select
                                value={poc}
                                label="Program of choice"
                                id="form-program-of-choice"
                                onChange={handlePOC}
                            >

                                <MenuItem selected value={"All"}>All</MenuItem>
                                <MenuItem value={"01"}>01 - Aids for daily living</MenuItem>
                                <MenuItem value={"02"}>02 - Ambulance services</MenuItem>
                                <MenuItem value={"03"}>03 - Audio (hearing) services</MenuItem>
                                <MenuItem value={"05"}>05 - Hospital service</MenuItem>
                                <MenuItem value={"07"}>07 - Medical supplies</MenuItem>
                                <MenuItem value={"08"}>08 - Nursing services</MenuItem>
                                <MenuItem value={"09"}>09 - Oxygen therapy</MenuItem>
                                <MenuItem value={"11"}>11 - Prosthetics and orthotics</MenuItem>
                                <MenuItem value={"12"}>12 - Related health services</MenuItem>
                                <MenuItem value={"13"}>13 - Special equipment</MenuItem>
                                <MenuItem value={"14"}>14 - Vision (eye) care</MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                    <div className='separator'>
                        <FormControl fullWidth>
                            <InputLabel>Province</InputLabel>
                            <Select
                                value={province}
                                label="Province"
                                id="form-province"
                                onChange={handleProvince}
                            >
                                <MenuItem value={"AB"}>Alberta</MenuItem>
                                <MenuItem value={"BC"}>British Columbia</MenuItem>
                                <MenuItem value={"MT"}>Manitoba</MenuItem>
                                <MenuItem value={"NB"}>New Brunswick</MenuItem>
                                <MenuItem value={"NL"}>Newfoundland and Labrador</MenuItem>
                                <MenuItem value={"NT"}>Northwest Territories</MenuItem>
                                <MenuItem value={"NS"}>Nova Scotia</MenuItem>
                                <MenuItem value={"NU"}>Nunavut</MenuItem>
                                <MenuItem value={"ON"}>Ontario</MenuItem>
                                <MenuItem value={"PE"}>Prince Edward Island</MenuItem>
                                <MenuItem value={"QC"}>Quebec</MenuItem>
                                <MenuItem value={"SK"}>Saskatchewan</MenuItem>
                                <MenuItem value={"YT"}>Yukon</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className='separator'>
                        <FormControl fullWidth>
                            <TextField
                                label="Search benefit code (eg. 101450)"
                                id="form-benefit-code"
                                value={benefitCode}
                                onChange={handleBenefitCode}
                            ></TextField>
                        </FormControl>
                    </div>
                    <label id="benefit_code_error" htmlFor={'user'}>enter benefit code</label>

                    <div className='separator'>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From date"
                                    id="form-start-date"
                                    value={fromDateFull}
                                    onChange={(e) => processFromDateFormat(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        

                    </div>
                    <label id="fromDate_error" >enter from date</label>
                    <div className='separator'>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To date"
                                    id="form-to-date"
                                    value={toDateFull}
                                    onChange={(e) => processToDateFormat(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        
                    </div>
                    <label id="toDate_error" >enter to date</label>

                    <div className='separator'>
                        <FormControl fullWidth>
                            <TextField
                                label="Search with keywords"
                                id="form-keywords"
                                value={keywords}
                                onChange={(e) => handleKeywords(e)}
                            ></TextField>
                        </FormControl>
                    </div>
                    <div className='separator'>
                        <FormControl fullWidth>
                            <Button onClick={handleSubmit} variant="contained" size="large">Search</Button>
                        </FormControl>
                    </div>
                </Box>
            </div>

        </div>
    );
}; 