import {Sform} from './Sform';
export const SideBar = props => {

  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
    const getData = (data) => {
        props.params(data) 
    }
    const getOtherProps = (data) => {
        props.otherProps(data)
    }

  return (
    <div className={sidebarClass}>
          <Sform onSubmit={getData} otherProps={getOtherProps}/>
    </div>
  );
};