// import React, { useState } from 'react';
import { useState, useMemo } from 'react';
import './mainpage.css';
import { Header } from './components/Header';
import { SideBar } from './components/Sidebar';
import { Content } from './components/Content';
import { Footer } from './components/Footer';


function App() {
    const [retrievedParams, setRetrievedParams] = useState(null);
    const [otherPropsData, setOtherPropsData] = useState(null);
    const [sidebarOpen, setSideBarOpen] = useState(true);
    const handleViewSidebar = () => setSideBarOpen(!sidebarOpen);

    const getData = (data) => {     
        setRetrievedParams(data)
    }
    const getOtherProps = (data) => {
        setOtherPropsData(data)
    }

    return (
            <div className="App">
                <Header onClick={handleViewSidebar}></Header>
                <SideBar otherProps={getOtherProps} params={getData} isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />
                <Content otherProps={otherPropsData} queryData={retrievedParams} isOpen={sidebarOpen} />
                <Footer />
            </div>
    );
}

export default App;

